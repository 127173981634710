import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import LoadingMain from '../components/loading';
import useDarkSide from '../hooks/useDarkSide';
import JivoChat from '../pages/Jivochat';
import Test from '../pages/Test';
import HotelDetailAmenities from '../pages/hotel/HotelDetailAmenities';
import HotelListByHotel from '../pages/hotel/HotelListByHotel';
import Axios from '../utils/httpsClinet';
import { setToken } from '../utils/tokenStorge';

const Home = lazy(() => import('../pages/Home'));
const HotelList = lazy(() => import('../pages/hotel/HotelList'));
const HotelDetail = lazy(() => import('../pages/hotel/HotelDetail'));
const HotelDetailComment = lazy(() => import('../pages/hotel/HotelDetailComment'));
const HotelGalleryFromDetail = lazy(() => import('../pages/hotel/HotelGalleryFromDetail'));
const HotelSelectingNumber = lazy(() => import('../pages/hotel/HotelSelectingNumber'));
const HotelTourBooking = lazy(() => import('../pages/hotel/HotelTourBooking'));
const HotelListBySearch = lazy(() => import('../pages/hotel/HotelListBySearch'));
const Services = lazy(() => import('../pages/Services'));
const Settings = lazy(() => import('../pages/Settings'));
const MyBooking = lazy(() => import('../pages/MyBooking'));
const MyBookingDetail = lazy(() => import('../pages/MyBookingDetail'));
const FindTour = lazy(() => import('../pages/FindTour'));
const AllNeed = lazy(() => import('../pages/AllNeed'));
const AllNeedItem = lazy(() => import('../pages/AllNeedItem'));
const TokenSet = lazy(() => import('../pages/TokenSet'));
const NotFound = lazy(() => import('../pages/NotFound'));

const AppRouter = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { i18n } = useTranslation();
	const { user } = useSelector(s => s);
	const [colorTheme, setTheme] = useDarkSide();
	const [darkSide, setDarkSide] = useState(colorTheme === 'dark' ? false : true);

	useEffect(() => {
		setDarkSide(colorTheme === 'dark' ? false : true);
		getUser();
	}, [location, darkSide, colorTheme]);

	useEffect(() => {
		if (window.Telegram?.WebApp) {
			window.Telegram.WebApp.expand();
		}
	}, []);

	 useEffect(() => {
			const backButton = window.Telegram.WebApp.BackButton;

			if (location.pathname === '/') {
				// Asosiy sahifada faqat "Close" tugmasini ko'rsatamiz
				// window.Telegram.WebApp.MainButton.show();
				backButton.hide();
				// window.Telegram.WebApp.MainButton.setText('Close');
				// window.Telegram.WebApp.MainButton.onClick(() => window.Telegram.WebApp.close());
			} else {
				// Boshqa sahifalarda "Back" tugmasini ko'rsatamiz
				window.Telegram.WebApp.MainButton.hide();
				backButton.show();
				backButton.onClick(() => navigate(-1)); // Orqaga qaytish
			}

			// Cleanup funksiyasi sahifa o'zgarganda eski event listener'larni tozalash uchun
			return () => {
				window.Telegram.WebApp.MainButton.offClick();
				backButton.offClick();
			};
		}, [location, navigate]);

	useEffect(() => {
		const value = getCookie('click-web-session');
		postClickOneTime(value);
	}, []);
	// const getCookie = (name) => {
	//   const value = `; ${document.cookie}`;
	//   const parts = value.split(`; ${name}=`);
	//   if (parts.length === 2) return parts.pop().split(";").shift();
	// };
	const getCookie = cname => {
		let name = cname + '=';
		let decodedCookie = decodeURIComponent(document.cookie);

		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	};

	// const getUser = access_token => {
	// 	Axios(null, access_token)
	// 		.get(`https://tripusk-api.bpm-tripusk.uz/api/v1/auth/me`)
	// 		.then(res => {
	// 			dispatch({ type: 'SET_USER', payload: res?.data });
	// 		})
	// 		.catch(err => {});
	// };
	const getUser = () => {
		Axios()
			.get(`api/v1/me`)
			.then(r => {
				dispatch({ type: 'SET_USER', payload: r?.data?.data ?? {} });
			})
			.catch(e => {})
			.finally(() => {});
	};
	const postClickOneTime = click_web_session => {
		const formData = new FormData();
		// formData.append('web_session', 'dc251856-5a4a-4fab-a183-f448ca8a5df0');
		formData.append('web_session', click_web_session);

		// alert(`click-web-session =>=> ${click_web_session}`);

		Axios()
			.post(`https://tripusk-api.bpm-tripusk.uz/api/v1/auth/click-login`, formData)
			.then(r => {
				setToken(r?.data?.access_token);
				// getUser(r?.data?.access_token);
			})
			.catch(e => {
				// console.log(e);
			})
			.finally(() => {});
	};

	useEffect(() => {
		const value = getCookie('click-web-session');
		const theme = getCookie('click-theme');
		const language = getCookie('click-language');
		setTheme(theme ? theme : 'white');

		localStorage.setItem('i18nextLng', language ? language : 'uz');
		i18n.changeLanguage(language ? language : 'uz');

		postClickOneTime(value);
	}, []);
	useEffect(() => {
		// window.ym faqat Metrika skripti yuklangandan keyin mavjud bo'ladi
		const trackPageView = () => {
			if (typeof window.ym !== 'undefined') {
				window.ym(98339232, 'hit', window.location.pathname);
			}
		};

		trackPageView();
	}, []);
	return (
		<div className={`App`}>
			<Routes>
				{/* hotels */}
				{/* <Route
					path='/'
					element={
						<Suspense
							fallback={
								<div>
									<LoadingMain />
								</div>
							}
						>
							<Home darkMode={darkSide} />
						</Suspense>
					}
				/> */}
				<Route
					path='/jivochat'
					element={
						<Suspense fallback={<LoadingMain />}>
							<JivoChat darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/test'
					element={
						<Suspense fallback={<LoadingMain />}>
							<Test darkMode={darkSide} />
						</Suspense>
					}
				/>

				<Route
					path='/hotels/:id'
					element={
						<Suspense fallback={<LoadingMain />}>
							<HotelList darkMode={darkSide} listType='0' />
						</Suspense>
					}
				/>

				<Route
					path='/hotels/detail/'
					element={
						<Suspense>
							<HotelDetail darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/detail/home/'
					element={
						<Suspense>
							<HotelDetail darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/detail/comment/:id'
					element={
						<Suspense>
							<HotelDetailComment darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/detail/amenities/:id'
					element={
						<Suspense>
							<HotelDetailAmenities darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/detail/gallery/'
					element={
						<Suspense fallback={<LoadingMain />}>
							<HotelGalleryFromDetail darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/detail/hotelselectingnumber/'
					element={
						<Suspense>
							<HotelSelectingNumber darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/detail/hoteltourbooking/:id'
					element={
						<Suspense fallback={<LoadingMain />}>
							<HotelTourBooking darkMode={darkSide} />
						</Suspense>
					}
				/>

				<Route
					path='/hotels/result'
					element={
						<Suspense>
							<HotelListBySearch darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/hotels/result-hotel'
					element={
						<Suspense>
							<HotelListByHotel darkMode={darkSide} />
						</Suspense>
					}
				/>

				{/* services */}

				<Route
					path='/services'
					element={
						<Suspense fallback={<LoadingMain />}>
							<Services darkMode={darkSide} />
						</Suspense>
					}
				/>

				<Route
					path='/settings'
					element={
						<Suspense fallback={<LoadingMain />}>
							<Settings darkMode={darkSide} />
						</Suspense>
					}
				/>

				{/* mybooking */}

				<Route
					path='/mybooking'
					element={
						<Suspense fallback={<LoadingMain />}>
							<MyBooking darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/mybooking/detail/:id'
					element={
						<Suspense fallback={<LoadingMain />}>
							<MyBookingDetail darkMode={darkSide} />
						</Suspense>
					}
				/>
				{/* all need */}
				<Route
					path='/all-need'
					element={
						<Suspense fallback={<LoadingMain />}>
							<AllNeed darkMode={darkSide} />
						</Suspense>
					}
				/>
				{/* all need item*/}
				<Route
					path='/all-need/:id'
					element={
						<Suspense fallback={<LoadingMain />}>
							<AllNeedItem darkMode={darkSide} />
						</Suspense>
					}
				/>
				{/* findtour */}

				<Route
					path='/'
					element={
						<Suspense
							fallback={
								<div>
									<LoadingMain />
								</div>
							}
						>
							<FindTour darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/home'
					element={
						<Suspense
							fallback={
								<div>
									<LoadingMain />
								</div>
							}
						>
							<Home darkMode={darkSide} />
						</Suspense>
					}
				/>
				<Route
					path='/set-token'
					element={
						<Suspense
							fallback={
								<div>
									<LoadingMain />
								</div>
							}
						>
							<TokenSet darkMode={darkSide} />
						</Suspense>
					}
				/>
				{/* not found */}
				<Route
					path='*'
					element={
						<Suspense fallback={<LoadingMain />}>
							<NotFound darkMode={darkSide} />
						</Suspense>
					}
				/>
				{/* Add more routes as needed */}
				{/* <Route path="/all" element={
          <Suspense fallback={	<LoadingMain />}>
            <FindTourAll darkMode={darkSide} />
          </Suspense>
        } /> */}
			</Routes>
		</div>
	);
};

const App = () => (
	<Router>
		<YMInitializer
			accounts={[98339232]} // Hisob raqamingiz
			options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true }}
			version='2'
		/>
		<AppRouter />
	</Router>
);

export default App;
