import React from 'react';

const InputDiv2 = props => {
	let darkMODE = props?.darkMode
		? `${props?.placeholder && (props?.value === '' || props?.value === undefined) ? 'text-starDust' : 'font-semibold text-white'}`
		: `${props?.placeholder && (props?.value === '' || props?.value === undefined) ? 'text-starDust' : 'font-semibold text-blueWood'}`;

	let darkModeError = props?.darkMode
		? `${props?.error ? 'border-red hover:border-red' : 'border-blueDark hover:border-blueRibbon'} `
		: `${props?.error ? 'border-red hover:border-red' : 'border-neutral hover:border-blueRibbon'} `;

	let darkModeBgText = props?.darkMode ? `text-blueCasper bg-tuna` : `text-blueWood bg-white `;

	return (
		<div
			{...props}
			className={` h-[60px] inline-flex gap-[16px] cursor-pointer relative w-full z-[1] border px-[20px] py-[18px] rounded-xl
       duration-200 ${darkModeError} ${darkMODE}
      `}
		>
			<span>{props?.icon}</span>
			<span>
				{props?.placeholder && (props?.value === '' || props?.value === undefined) ? props?.placeholder : props?.value}
				{props?.checkedItems &&
					props?.checkedItems.length > 0 &&
					`, ${props.checkedItems
						.filter(item => item.label !== 'Все')
						.map(item => item.label)
						.join(', ')}`}
			</span>
			<div className='absolute top-[-15px] left-0'>
				<span className={`text-sm font-medium px-2 ml-[10px]  bg-white text-[#2B3F5A]  dark:bg-[#242429] dark:text-white `}>{props?.title}</span>
			</div>
		</div>
	);
};

export default InputDiv2;
