import Title from '../../Title';

const ClickTravel = ({ list, title, darkMode, shadow = true, titleShow = true, randomTrue = false, getRandomColor, container_main = true }) => {
	return (
		<>
			{titleShow ? (
				<div className='container_main'>
					<Title text={title} className={`line-clamp-1 ${shadow === false ? 'mb-1' : ''}`} darkMode={darkMode} />
				</div>
			) : (
				' '
			)}

			<div className={container_main ? 'container_main !pr-0' : '!pr-0'}>
				<div className={`overflow-x-scroll mt-[10px] pt-0 ${shadow === false ? ' py-2' : 'py-6'}`}>
					<div className='flex gap-[10px] min-w-[250px]'>
						{list.map((item, index) => (
							<div
								key={index}
								className={`inline-flex items-center gap-2 whitespace-nowrap rounded-[10px] -mr-1 text-white font-normal text-sm
                
                ${shadow === false ? 'px-[10px] py-[5px]' : 'px-[15px] py-[12px] shadow-md '}
                `}
								style={
									shadow
										? {
												boxShadow: '4px 8px 14px 0px #0054A226',
												background: item?.color,
										  }
										: {
												background: item?.color,
										  }
								}
							>
								{item?.icon && shadow === false ? <span>{item?.icon}</span> : ''}
								<span>{item.title}</span>
							</div>
						))}
						<div className='w-[10px] h-[10px] opacity-0'>.</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ClickTravel;
