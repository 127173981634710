import React from 'react';

const Title = ({ size = 'base', weight = 'semibold', color = '#2B3F5A', className, text, darkMode, iconLeft, onClick = () => {} }) => {
	return (
		<p  onClick={onClick} className={`my-[14px] inline-flex items-center gap-2 ${darkMode ? '!text-white' : `!text-[${color}]`} font-${weight} text-${size} ${className}`}>
			<span>{iconLeft}</span>
			<span>{text}</span>
		</p>
	);
};

export default Title;
