import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../homeS3Icon';
import ModalBottom from '../../modal/ModalBottom';

const ModalNumberOfTourists = ({
	modalNumberOfTourists,
	setModalNumberOfTourists,
	list,
	obj,
	setObj,
	objError,
	setObjError,
	childrenCount,
	setChildrenCount,
	adultCounter,
	setAdultCounter,
	ages,
	setAges,
	addChild,
	setAddChild,
	setModalNumberOfDays,
	darkMode,
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		setObj(pV => ({
			...pV,
			number_of_tourists: obj?.number_of_tourists,
		}));
	}, [obj?.number_of_tourists]);

	const handleAdultCounter = delta => {
		const newCount = adultCounter + delta;
		if (newCount >= 1) {
			setAdultCounter(newCount);
		}
	};
	const handleChildrenChange = delta => {
		const newCount = Math.max(0, childrenCount + delta);
		setChildrenCount(newCount);

		if (newCount > childrenCount) {
			// Add new entries starting from 1
			const newAges = [...ages];
			for (let i = childrenCount + 1; i <= newCount; i++) {
				newAges.push(1); // Initialize each new entry with 1
			}
			setAges(newAges);
		} else {
			// Remove entries
			setAges(ages.slice(0, newCount));
		}
	};

	const handleAgeChange = (index, delta) => {
		const newAges = ages.map((age, i) => (i === index ? Math.min(17, Math.max(1, age + delta)) : age));
		setAges(newAges);
	};

	const ageCategory = age => {
		if (age === 1 || age === 0) {
			return age + ` ${t('home.year')}`;
		} else if (age >= 2 && age <= 4) {
			return age + ` ${t('home.year1')}`;
		} else if (age >= 5 && age <= 17) {
			return age + ` ${t('home.year3')}`;
		} else {
			return age + ` ${t('home.year4')}`;
		}
	};

	const clickFunction = () => {
		let allCount = adultCounter + ages?.length;
		setObj(pV => ({
			...pV,
			number_of_tourists: allCount,
		}));
		setObjError(pV => ({
			...pV,
			number_of_tourists: false,
		}));
		setModalNumberOfTourists(false);
		// setModalNumberOfDays(true);
	};

	const closeFunction = () => {
		setModalNumberOfTourists(false);
		// setAddChild(false);
		// setChildrenCount(0);
		// setAdultCounter(1);
		// setAges([]);
		// setObj((pV) => ({
		//   ...pV,
		//   number_of_tourists: 1,
		// }));
	};

	return (
		<div className='w-full'>
			<ModalBottom
				close={() => closeFunction()}
				modal={modalNumberOfTourists}
				heightModal='min-h-[20vh] max-h-[80vh]'
				btnShow={true}
				textBtn={t('home.save')}
				translateY='translate-y-[80vh]'
				clickFunction={clickFunction}
				content={
					<div className='w-full '>
						<div className='flex items-center justify-between w-full gap-3'>
							<p className='font-semibold text-xl dark:text-white'>{t('home.number_of_tourists2')}</p>
							<CloseIcon fill={darkMode ? '#fff' : '#2B3F5A'} className='cursor-pointer' onClick={() => closeFunction()} />
						</div>

						<div className='mt-[20px]'>
							<div className='flex items-center justify-between border-b border-neutral py-[15px]'>
								<span className='mr-2 text-sm font-medium dark:text-white'>
									<span className='font-semibold text-lg select-none dark:text-white'>{t('home.adults')}</span> ({t('home.over_16_years_old')})
								</span>
								<div className='flex items-center gap-1'>
									<button
										onClick={() => handleAdultCounter(-1)}
										className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
									>
										-
									</button>
									<span className='select-none mx-2 font-semibold text-lg dark:text-white'>{adultCounter}</span>
									<button
										onClick={() => handleAdultCounter(1)}
										className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
									>
										+
									</button>
								</div>
							</div>
							<div className='flex items-center justify-between border-b border-neutral'>
								{!addChild ? (
									<div className='text-base font-normal text-blueRibbon w-full py-[15px] cursor-pointer dark:text-white' onClick={() => setAddChild(true)}>
										+ {t('home.add_childs')}
									</div>
								) : (
									<div className='flex items-center justify-between w-full py-[15px]'>
										<span className='mr-2 font-semibold text-lg dark:text-white'>{t('home.childs')}</span>
										<div className='flex items-center gap-1'>
											<button
												onClick={() => handleChildrenChange(-1)}
												className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
											>
												-
											</button>
											<span className='select-none mx-2 font-semibold text-lg dark:text-white'>{childrenCount}</span>
											<button
												onClick={() => handleChildrenChange(1)}
												className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
											>
												+
											</button>
										</div>
									</div>
								)}
							</div>

							{ages.map((age, index) => (
								<div key={index} className='flex items-center justify-between w-full py-[15px] border-b border-neutral'>
									<span className='mr-2 font-semibold text-lg dark:text-white'>{t('home.child_age')}</span>
									<div className='flex items-center gap-1'>
										<button
											onClick={() => handleAgeChange(index, -1)}
											className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
										>
											-
										</button>
										<span className='select-none mx-2 font-semibold text-lg dark:text-white'>{ageCategory(age)}</span>
										<button
											onClick={() => handleAgeChange(index, 1)}
											className='select-none w-[27px] h-[27px] bg-[#EEF2FF] flex justify-center items-center text-blueRibbon font-normal text-base rounded-full'
										>
											+
										</button>
									</div>
								</div>
							))}
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ModalNumberOfTourists;
