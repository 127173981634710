import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MobileTab from '../components/mobiletab';
import NavigationOne from '../components/NavigationOne';

const Test = ({ darkMode }) => {
	const { t } = useTranslation();

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://experience.tripster.ru/partner/whitelabel.js?partner=prestigetravel1915016';
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);
	return (
		<React.Fragment>
			<div className=''>
				<NavigationOne linkClose={true} text={t('home.title')} darkMode={darkMode} />
			</div>
			<MobileTab />
		</React.Fragment>
	);
};

export default Test;
