import React from 'react';
import { useTranslation } from 'react-i18next';

const PrestigeBanner = ({ darkMode }) => {
	const { t } = useTranslation();
	return (
		<div className='bg-white dark:bg-[#34353F] py-[13px] flex   gap-[20px]   justify-center rounded-[5px] mx-[10px] items-center'>
			{darkMode ? <img width={106} src='/images/prestige_white.png' alt='' /> : <img width={106} src='/images/prestige_black.png' alt='' />}
			<div>
				<p className='text-[#000000] w-[220px] dark:text-white  text-sm font-medium'>{t('home.prestige_banner')}</p>
			</div>
		</div>
	);
};

export default PrestigeBanner;
