import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { BagIcon, PalmaTree, UserIcon } from '../components/headIcons';

const MobileTabStyle = styled.div`
	/* height: 60px;s; */
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1030;
	width: 100%;
	justify-content: center;
	display: flex;
	& .link {
		cursor: pointer;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		& .icon {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& .title {
			margin-top: 5px;
			color: #9e9e9e;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			text-align: center;
		}
		&:hover,
		&.active {
			& .icon {
				#mask0_1854_535,
				#mask0_1858_958 {
					& g > path,
					& path {
						fill: #fff !important;
					}
				}
				& g > path,
				& path {
					/* fill: #0054a2 !important; */
					stroke: #0054a2 !important;
				}
			}
			& .my-apps {
				& circle,
				& path {
					stroke: #0054a2;
				}
			}
			& .title {
				color: #0054a2;
			}
		}
	}
`;
const MobileTab = ({ darkMode }) => {
	const { user, login_modal } = useSelector(s => s);
	const { t, i18n } = useTranslation();

	return (
		<>
			<MobileTabStyle className={`!bg-white dark:!bg-[#242429] `}>
				<div
					className={` mobile-tab  container_main w-full flex items-center  py-[17px] !px-0 bg-white border-t border-[#dee7ff]   dark:!bg-[#242429] dark:border-[#646778] dark:border-t 
					`}
				>
					<div className={`flex justify-around items-center w-full   `}>
						<NavLink className='link' to='/'>
							<div className='my-apps '>{PalmaTree}</div>
							<div className='title'>{t('home.find_tour')}</div>
						</NavLink>
						{/* <NavLink className='link min-h-[50px]' to='/mybooking'>
							<div className='icon  min-h-[30px]'>{BookingIcon}</div>
							<div className='title'>{t('home.my_reservations')}</div>
						</NavLink> */}
						<NavLink className='link ' to='/all-need'>
							<div className='icon '>{BagIcon}</div>
							<div className='title'>{t('home.all_need')}</div>
						</NavLink>

						<NavLink className='link' to='/services'>
							<div className='my-apps '>{UserIcon}</div>
							<div className='title'>{t('home.account')}</div>
						</NavLink>
					</div>
				</div>
			</MobileTabStyle>
		</>
	);
};

export default MobileTab;
