import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'toastr/build/toastr.min.css';
import App from './App';
import './index.css';
import './plyr.css';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';
import './utils/i18n';
import createStore from './utils/store';

const root = createRoot(document.getElementById('root'));
// init();
// backButton.mount();

const store = createStore();
root.render(
	<Provider store={store}>
		<App />
		{/* <BackButton /> */}
	</Provider>
);
reportWebVitals();
// serviceWorker.unregister();
