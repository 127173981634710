import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Axios from '../utils/httpsClinet';
import { planIcon } from './homeS3Icon';

const HeaderDetailParams = ({ darkMode }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [params, setParams] = useState({});
	const [obj, setObj] = useState({
		departure: '',
		where: '',
		departure_date: '',
		number_of_tourists: 2,
		number_of_days: 7,
	});
	useEffect(() => {
		const decodedURL = window.location.search;
		const urlParams = new URLSearchParams(decodedURL);

		const paramsObject = {
			town_from_inc: urlParams.get('town_from_inc'),
			tour_operator_id: urlParams.get('tour_operator_id'),
			state_id: urlParams.get('state_id'),
			checkin: urlParams.get('checkin'),
			nights: urlParams.get('nights'),
			adult: urlParams.get('adult'),
			childs: urlParams.get('childs'),
			town_from_inc_name: urlParams.get('town_from_inc_name'),
			town_names: urlParams.getAll('town_names[]'),
			town: urlParams.getAll('town[]'),
		};

		setParams(paramsObject);
		getTownFrom(paramsObject?.town_from_inc);
		getTownStates(paramsObject?.town_from_inc, paramsObject?.tour_operator_id, paramsObject?.state_id);
	}, []);

	const formatDate = checkin => {
		if (!checkin) {
			console.error('checkin is undefined');
			return '';
		}

		const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

		const year = parseInt(checkin.slice(0, 4));
		const month = parseInt(checkin.slice(4, 6)) - 1; // JS months are 0-indexed
		const day = parseInt(checkin.slice(6, 8));

		if (isNaN(year) || isNaN(month) || isNaN(day)) {
			console.error('Invalid date format in checkin:', checkin);
			return '';
		}

		const checkinDate = new Date(year, month, day);

		return `${checkinDate.getDate()} ${months[checkinDate.getMonth()]}`;
	};

	const getTownFrom = town_from_inc => {
		setLoading(true);
		Axios()
			.get(`api/v1/search/town-from`)
			.then(r => {
				let data = r?.data?.data;

				let findObj = data?.find(el => el?.somo_id === +town_from_inc);
				if (town_from_inc !== undefined) {
					setObj(pV => ({
						...pV,
						departure: findObj?.name,
					}));
				}
			})
			.catch(e => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getTownStates = (somo_id, tour_operator_id, state_id) => {
		Axios()
			.get(`api/v1/search/states?town_from_inc=${somo_id}&tour_operator_id=${tour_operator_id}`)
			.then(r => {
				let data = r?.data?.data;
				let findObj = data?.find(el => el?.somo_id === +state_id);
				if (state_id !== undefined) {
					setObj(pV => ({
						...pV,
						where: findObj?.name,
					}));
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<div className={`whitespace-normal rounded-xl ${darkMode ? 'text-white' : 'text-blueWood'}`}>
			<div className='flex justify-between gap-3'>
				<div className='flex gap-[10px] items-start'>
					{loading ? <Skeleton width={100} height={23} style={{ borderRadius: '8px' }} baseColor='#cccccc' /> : <p className='text-lg font-semibold'>{obj?.departure}</p>}
					<p className='mt-[7px]'>{planIcon}</p>
					{loading ? (
						<Skeleton width={100} height={23} style={{ borderRadius: '8px' }} baseColor='#cccccc' />
					) : (
						<p className='text-lg font-semibold break-all line-clamp-1'>{obj?.where}</p>
					)}
				</div>
			</div>
			<div className='whitespace-nowrap text-sm font-normal'>
				{new URLSearchParams(window.location.search)?.get('checkin') ? formatDate(new URLSearchParams(window.location.search)?.get('checkin')) + ', ' : ''}
				{+new URLSearchParams(window.location.search)?.get('adult') + +new URLSearchParams(window.location.search)?.get('childs')} {t('home.turist')},{' '}
				{new URLSearchParams(window.location.search)?.get('nights')} {t('home.nights')}
			</div>
		</div>
	);
};

export default HeaderDetailParams;
