import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../homeS3Icon';
import { ArrowRight } from '../../itemIcon';
import ModalBottom from '../../modal/ModalBottom';

const ModalNumberOfDays = ({ modalNumberOfDays, setModalNumberOfDays, list, obj, setObj, objError, setObjError, darkMode }) => {
	const { t } = useTranslation();
	const handleSet = value => {
		setObj(pV => ({
			...pV,
			number_of_days: value,
		}));
		setObjError(pV => ({
			...pV,
			number_of_days: false,
		}));
		setModalNumberOfDays(false);
	};

	return (
		<div className='w-full'>
			<ModalBottom
				close={() => setModalNumberOfDays(false)}
				modal={modalNumberOfDays}
				heightModal='min-h-[20vh] max-h-[80vh]'
				btnShow={false}
				translateY='translate-y-[80vh]'
				content={
					<div className='w-full'>
						<div className='flex items-center justify-between w-full gap-3'>
							<p className='font-semibold text-xl  dark:text-white '> {t('home.number_of_days2')}</p>
							<CloseIcon fill={darkMode ? '#fff' : '#2B3F5A'} className='cursor-pointer' onClick={() => setModalNumberOfDays(false)} />
						</div>
						{list?.map((item, index) => {
							return (
								<div
									key={index}
									onClick={() => handleSet(item?.title || item?.somo_id)}
									className='flex items-center justify-between gap-3 px-3 border-b border-neutral dark:border-[#4B4B59]  py-[15px] last:border-none cursor-pointer'
								>
									<p className=' dark:text-white'>{item?.title || item?.somo_id}</p>
									<ArrowRight fill={darkMode ? '#B3B7CE' : '#2B3F5A'} />
								</div>
							);
						})}
					</div>
				}
			/>
		</div>
	);
};

export default ModalNumberOfDays;
